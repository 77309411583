import React, { Fragment } from "react";
import scan2payBanner from "assets/images/YOOZ-SCAN2PAY-BANNER.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import Scan2PayHeader from "./header";
import { makeStyles } from "@material-ui/core/styles";
import Scan2PayContent from "./content";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
    root: {
      margin: "auto",
      marginBottom: 20,
    },
  }));

export default function Scan2PayGns() {
    useDocumentTitle("Pay for Goods");

    const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageBanner img={scan2payBanner} />
      
        <Scan2PayHeader />
        <Scan2PayContent/>
    
    </div>
  );
}
