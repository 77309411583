import React, { Fragment } from "react";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PartnersContent from "./content";
import PartnersHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
 
export default function Partners() {
    useDocumentTitle("Business Onboarding Documents");
  return (
    <Fragment>
      <ContentWrapper>
        <PartnersHeader />
        <PartnersContent />
      </ContentWrapper>
    </Fragment>
  );
}
