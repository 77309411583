import {Button, Container, Link} from "@material-ui/core";
import React from "react";
import ContentList from "components/public-dashboard/components/contentList";

export default function ContactContent() {
  return (
    <Container>
      <ContentList
        title=""
        items={[
          "Need to adjust your Cookie settings or opt out of non-essential Cookies? Click on the “Reset Preferences” button.",
            <Button
                type="submit"
                variant="contained"
                color="primary"
                id="submitRegister"
                onClick={()=>{
                    localStorage.removeItem("cookie_consent");
                    document.cookie = "cookie_consent" +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    window.location.reload();
                }}
            >
                Reset Preferences
            </Button>,
        ]}
      />
    </Container>
  );
}
