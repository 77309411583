import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import topupBanner from "assets/images/topup_banner.jpg";
import PageBanner from "components/public-dashboard/components/page_banner";
import TopupContent from "./content";
import TopupHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginBottom: 20,
  },
}));

export default function TopUpInfo() {
    useDocumentTitle("Top Up Info");

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageBanner img={topupBanner} />
      <TopupHeader />
      <TopupContent />
    </div>
  );
}
