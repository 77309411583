import { Container } from "@material-ui/core";
import React, { Fragment } from "react";
import aboutusbanner from "assets/images/aboutus_banner.jpg";
import { getToken } from "middleware/storage";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import AboutusHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function About() {
    useDocumentTitle("About Us");

  const token = getToken();

  if (token) {
    return (
      <Container maxWidth="lg">
        <AboutusHeader />
      </Container>
    );
  }
  return (
    <Fragment>
      {!token && <PageBanner img={aboutusbanner} />}
      <ContentWrapper>
        <AboutusHeader />
      </ContentWrapper>
    </Fragment>
  );
}
