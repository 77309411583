import { Button, Container, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Favorite } from "@material-ui/icons";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import xmasGiftBox from "assets/images/xmas_gift_box.png";
import CADV from "assets/images/CADV.png";
import AWN from "assets/images/AWN.png";
import ITNAC from "assets/images/ITNAC.png";
import SEWA from "assets/images/sewa-donate.png";
import ContentList from "components/public-dashboard/components/contentList";
import MerchantDescription from "components/public-dashboard/components/merchantDescription";
import { useDispatch } from "react-redux";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import { Link } from "react-router-dom";
import TextList from "components/public-dashboard/components/TextList";
import signUp_img from "assets/images/home_content_5.png";
import HomeHeader from "components/public-dashboard/features/agents/header";

const useStyles = makeStyles((theme) => ({
    img: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    yoozBlueLink: {
      color:"#00AFE2"
    },
  }));
  
  export default function DonateContent() {
    const classes = useStyles();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const xs = useMediaQuery(theme.breakpoints.down("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();


    return (
        <Container className={classes.root}>  

        <MerchantDescription
              title="CADV"
              items={[

                  <span>
                      The <a href="https://coalitionagainstdomesticviolence.org/" target="_blank" className={classes.yoozBlueLink}>Coalition Against Domestic Violence </a> is a non proﬁt organisation comprising individuals and organisations dedicated to combatting domestic abuse and other forms of gender based violence through advocacy, training, free counselling, legal advisory services and public awareness.
                  </span>,
                    <Button
                    startIcon={<Favorite/>}
                    component={RouterLink}
                    to={`/quickpay/${process.env.REACT_APP_CADV_PROVIDER_ID}/DONATECADV`}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(setTabPosition(8));
                    }}
                    style={{ borderRadius: 20, marginLeft: 10 }}
                  >
                    Donate
                  </Button>
              ]}
              logo={CADV}
              alt="CADV"
              link="https://coalitionagainstdomesticviolence.org/"
              
          ></MerchantDescription>       
          <MerchantDescription
              title="AWN"
              items={[

                  <span>
                      The <a href="https://animalwelfarenetwork.com/" target="_blank" className={classes.yoozBlueLink}>Animal Welfare Network </a> is a network of volunteers within T&T who joined together to beneﬁt animals in local communities. Their focus is on educating people about the humane treatment of animals, while promoting spaying and neutering, as a means to reduce the stray animal problem.
                  </span>,
                    <Button
                    startIcon={<Favorite/>}
                    component={RouterLink}
                    to={`/quickpay/${process.env.REACT_APP_AWN_PROVIDER_ID}/DONATEAWN`}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(setTabPosition(8));
                    }}
                    style={{ borderRadius: 20, marginLeft: 10 }}
                  >
                    Donate
                  </Button>
              ]}
              logo={AWN}
              alt="AWN"
              link="https://animalwelfarenetwork.com/"
          ></MerchantDescription>
          <MerchantDescription
              title="ITNAC"
              items={[

                  <span>
                      Is There Not A Cause? <a href="https://itnactt.com/" target="_blank" className={classes.yoozBlueLink}>ITNAC</a> is a Non-Profit Organization that serves the local, regional and international community. Their goal is to assist in disaster relief, community development and poverty alleviation in as many countries as possible. They are committed to <b>Making a Difference One Life At a Time</b>.
                  </span>,
                    <Button
                    startIcon={<Favorite/>}
                    component={RouterLink}
                    to={`/quickpay/${process.env.REACT_APP_ITNAC_PROVIDER_ID}/DONATEITNAC`}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(setTabPosition(8));
                    }}
                    style={{ borderRadius: 20, marginLeft: 10 }}
                  >
                    Donate
                  </Button>
              ]}
              logo={ITNAC}
              alt="ITNAC"
              link="https://itnactt.com/"
          ></MerchantDescription>
              <MerchantDescription
              title="SEWA TT"
              items={[

                  <span>
                      <a href="https://www.facebook.com/sewatt.org" target="_blank" className={classes.yoozBlueLink}>Sewa International TT</a>  is a non-profit service organisation focused on
                        building a national network of volunteers dedicated to working for the
                        unity of the society and the removal of social disparities in Trinidad
                        and Tobago. Their initiatives include humanitarian aid, environmental
                        conservation, disaster response, and health & wellness.
                  </span>,
                    <Button
                    startIcon={<Favorite/>}
                    component={RouterLink}
                    to={`/quickpay/${process.env.REACT_APP_SEWA_PROVIDER_ID}/DONATESEWA`}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      dispatch(setTabPosition(8));
                    }}
                    style={{ borderRadius: 20, marginLeft: 10 }}
                  >
                    Donate
                  </Button>
              ]}
              logo={SEWA}
              alt="SEWA TT"
              link="https://www.facebook.com/sewatt.org"
          ></MerchantDescription>

        </Container>
    )
  };