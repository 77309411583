import React, { Fragment } from "react";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import Content from "./content";
import Header from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function Partners() {
    useDocumentTitle("Agent Onboarding Documents");

  return (
    <Fragment>
      <ContentWrapper>
        <Header />
        <Content />
      </ContentWrapper>
    </Fragment>
  );
}
