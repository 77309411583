import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import bg from "assets/images/login_bg.jpg";
import Loading from "components/shared/loader/loader";
import ForgotPasswordDialog from "components/public-dashboard/features/forgot_password";
import SmsVerificationDialog from "components/public-dashboard/features/sms_verification";
import LoginForm from "./login_form";
import {sendEmailOtp, sendOtp} from "../../../../actions/auth";
import {RESEND_OTP_FAILURE, RESEND_OTP_SUCCESS} from "../../../../constants/actionTypes";
import {showSnackBar} from "../../../../reducers/uiGlobal";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${bg})`,
    backgroundSize: "cover",
    [theme.breakpoints.up("xl")]: {
      backgroundSize: "cover",
      height: "100vh",
    },
    backgroundRepeat: "no-repeat",
  },
}));

export default function Login(props) {
  useDocumentTitle("Login");

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    onLogin,
    onChangeFormField,
    setForgotPwdisibility,
    changeUser,
    authReducer,
    loginError,
    otpType,
    setOtpType,
    setMobileOtp,
    isVisible,
    setVisibility,
    onOtpVarify,
    mobNum,
    otpError,
    resendOtp,
    tokens,
    isForgot,
    emailError,
    sendResetLink,
    username,
    timeoutDuration,
    sendEmailOtp,
    sendSMSOtp,
    lastEmailOtpExpirationTime,
    lastSMSOtpExpirationTime
  } = props;

  const loading = useSelector((state) => state.uiGlobal.loading);


  function renderLoginPage() {
    return (
      <LoginForm
        onChangeFormField={onChangeFormField}
        onLogin={onLogin}
        setVisibility={setForgotPwdisibility}
        authReducer={authReducer}
        loginError={loginError}
      />
    );
  }

  return (
    <div className={classes.root}>
      {loading && <Loading />}
      {renderLoginPage()}
      {isVisible && (
        <SmsVerificationDialog
          open={isVisible}
          email={username}
          sendSMSOtp={sendSMSOtp}
          lastEmailOtpExpirationTime={lastEmailOtpExpirationTime}
          lastSMSOtpExpirationTime={lastSMSOtpExpirationTime}
          sendEmailOtp={sendEmailOtp }
          mobile={tokens ? tokens.phoneNumber : mobNum}
          handleClose={setVisibility}
          timeoutDuration={timeoutDuration}
          otpType={otpType}
          setOtpType={setOtpType}
          setMobileOtp={setMobileOtp}
          onOtpVarify={onOtpVarify}
          resendOtp={resendOtp}
          error={otpError}
          title="Login"
          title2="Verification"
        />
      )}
      <ForgotPasswordDialog
        open={isForgot}
        handleClose={setForgotPwdisibility}
        onChangeFormField={onChangeFormField}
        authReducer={authReducer}
        sendResetLink={sendResetLink}
        error={emailError}
        title="Forgot"
        title2="Password"
      />
    </div>
  );
}
