import React, { Fragment } from "react";
import partnersBanner from "assets/images/partners_banner.jpg";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import PartnersContent from "./content";
import PartnersHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function Partners() {
  useDocumentTitle("Partners");
  return (
    <Fragment>
      <PageBanner img={partnersBanner} />
      <ContentWrapper>
        <PartnersHeader />
        <PartnersContent />
      </ContentWrapper>
    </Fragment>
  );
}
