import React, { Fragment } from "react";
import donateBanner from "assets/images/donate_banner.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";

import DonateContent from "./content";
import DonateHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function Donate() {
    useDocumentTitle("Donate");
  return (
    <Fragment>
      <PageBanner img={donateBanner} />
      <ContentWrapper>
        <DonateHeader />
        <DonateContent /> 
      </ContentWrapper>
    </Fragment>
  );
}
