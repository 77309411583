import {React, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./store/configureStore";
import { setLoading } from "./reducers/uiGlobal";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {API_ROOT} from "./constants/actionTypes";
const store = configureStore();
ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <App />
        <CookieConsentBanner />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    setLoading(true);
    updateServiceWorker(reg && reg.waiting);
  },
});

const updateServiceWorker = (waitingWorker) => {
  if (waitingWorker) {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });

    waitingWorker.addEventListener("statechange", (e) => {
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  }
};


function CookieConsentBanner(){
    const CONSENT_COOKIE_NAME = 'cookie_consent';
    const CONSENT_COOKIE_DAYS = process.env.REACT_APP_COOKIE_CONSENT_RESET_DAYS || 5;
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };
    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/;SameSite=Lax`;
    };
    const loadGoogleAnalytics = (measurementId) => {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
        script.async = true;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', measurementId);
    };
    const initialConsent = () => {
        if (typeof window === 'undefined') return null;
        if (getCookie(CONSENT_COOKIE_NAME)) return 'declined';
        if (localStorage.getItem(CONSENT_COOKIE_NAME)) return 'accepted';
        return null;
    };
    const [consent, setConsent] = useState(initialConsent());

    const styles = {
        container: {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            maxWidth: '420px',
            padding: '20px',
            zIndex: 1000,
            fontFamily: 'system-ui, -apple-system, sans-serif',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },
        title: {
            margin: 0,
            fontSize: '18px',
            fontWeight: 600,
            color: '#1a1a1a',
        },
        description: {
            margin: 0,
            fontSize: '14px',
            lineHeight: '1.5',
            color: '#666666',
        },
        link: {
            color: '#0070f3',
            textDecoration: 'none',
            marginLeft: '4px',
            fontWeight: 500,
            ':hover': {
                textDecoration: 'underline',
            },
        },
        optionsContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            borderTop: '1px solid #eee',
            paddingTop: '16px',
        },
        option: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        optionText: {
            fontSize: '14px',
            color: '#333',
        },
        toggleContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
        toggleLabel: {
            fontSize: '12px',
            color: '#666',
        },
        toggle: {
            width: '48px',
            height: '24px',
            borderRadius: '12px',
            position: 'relative',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
        },
        toggleSwitch: {
            position: 'absolute',
            top: '2px',
            left: '2px',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'white',
            transition: 'transform 0.2s',
        },
        buttonContainer: {
            display: 'flex',
            gap: '12px',
            justifyContent: 'flex-end',
        },
        primaryButton: {
            padding: '8px 16px',
            backgroundColor: '#0070f3',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontWeight: 500,
            transition: 'background-color 0.2s',
            ':hover': {
                backgroundColor: '#0051a2',
            },
        },
        secondaryButton: {
            padding: '8px 16px',
            backgroundColor: 'transparent',
            color: '#666',
            border: '1px solid #ddd',
            borderRadius: '6px',
            cursor: 'pointer',
            fontWeight: 500,
            transition: 'all 0.2s',
            ':hover': {
                backgroundColor: '#f5f5f5',
            },
        },
    };

    const [isVisible, setIsVisible] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        necessary: true,
        analytics: consent === "accepted" || false,
    });

    useEffect(() => {
        if (consent === 'accepted') {
            // If accepted, store in localStorage and load GA
            localStorage.setItem(CONSENT_COOKIE_NAME, 'true');
            loadGoogleAnalytics(process.env.REACT_APP_GTAG_CONFIG);
            setIsVisible(false);
        } else if (consent === 'declined') {
            // If declined, set cookie and disable GA
            setIsVisible(false);
            setCookie(CONSENT_COOKIE_NAME, 'false', CONSENT_COOKIE_DAYS);
        }
    }, [consent]);


    const handleAccept = () => {
        setConsent("accepted");
        setIsVisible(false);
    };
    const toggleOptions = () => setShowOptions(!showOptions);

    const handleToggle = (type) => {
        setCookiePreferences(prev => ({
            ...prev,
            [type]: !prev[type]
        }));
    };

    const handleSave = () => {
        setIsVisible(false);
        if(cookiePreferences.analytics){
            setConsent('accepted');
        }else {
            setConsent("declined");
        }
        // Save preferences logic here
    };

    if (!isVisible) return null;

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h4 style={styles.title}>Your Privacy Choices</h4>

                <p style={styles.description}>
                    We use cookies and related technologies for site operation to enhance your experience and analytics, as described in our  <a href={`${API_ROOT}document/yo-privacy-policy`} style={styles.link} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>. You can choose to consent to use these cookies or reject non-essential cookies.

                </p>

                {showOptions && (
                    <div style={styles.optionsContainer}>
                        <div style={styles.option}>
                            <span>Essential Cookies</span>
                            <div style={styles.toggleContainer}>
                                <span style={styles.toggleLabel}>Always active</span>
                                <div style={{...styles.toggle, backgroundColor: '#0070f3'}}>
                                    <div style={{
                                        ...styles.toggleSwitch,
                                        transform: 'translateX(20px)'
                                    }} />
                                </div>
                            </div>
                        </div>

                        {Object.entries(cookiePreferences).map(([type, enabled]) => {
                            if (type === 'necessary') return null;
                            return (
                                <div key={type} style={styles.option}>
                  <span style={styles.optionText}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} Cookies
                  </span>
                                    <div
                                        style={{
                                            ...styles.toggle,
                                            backgroundColor: enabled ? '#0070f3' : '#e0e0e0'
                                        }}
                                        onClick={() => handleToggle(type)}
                                    >
                                        <div style={{
                                            ...styles.toggleSwitch,
                                            transform: enabled ? 'translateX(20px)' : 'translateX(0)'
                                        }} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div style={styles.buttonContainer}>
                    {showOptions ? (
                        <>
                            <button
                                onClick={handleSave}
                                style={styles.secondaryButton}
                            >
                                Save Preferences
                            </button>
                            <button
                                onClick={handleAccept}
                                style={styles.primaryButton}
                            >
                                Allow All
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={toggleOptions}
                                style={styles.secondaryButton}
                            >
                                Manage
                            </button>
                            <button
                                onClick={handleAccept}
                                style={styles.primaryButton}
                            >
                                Accept
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};