import { Box, Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "components/public-dashboard/components/iconHeader";

export default function ContactHeader() {
  const theme = useTheme();

  return (
    <Container>
      <Box mb={3}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <IconHeader
              title="Opt Out"
              iconColor={theme.palette.primary.main}
              titleColor="#FBB111"
              titleLg="h2"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
