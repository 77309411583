import React, { Fragment } from "react";
import CollectDonationsBanner from "assets/images/YOOZ-WEBSITE-COLLECT-DONATIONS-banner.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import PartnersContent from "./content";
import PartnersHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function Partners() {
    useDocumentTitle("Collect Donations");
  return (
    <Fragment>
      <PageBanner img={CollectDonationsBanner} />
      <ContentWrapper>
        <PartnersHeader />
        <PartnersContent />
      </ContentWrapper>
    </Fragment>
  );
}
