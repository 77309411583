import React, { Fragment } from "react";
import img1 from "assets/images/Agent-Network-Locations-Banner.png";
import ContentSection from "./agent_table";
import PageBanner from "components/public-dashboard/components/page_banner";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

export default function Agents() {
    useDocumentTitle("Agent Locations");
  return (
    <Fragment>
      <PageBanner img={img1} />
      <ContentWrapper>
        <ContentSection />
      </ContentWrapper>
    </Fragment>
  );
}
