import React, { Fragment } from "react";
import secureBanner from "assets/images/YOOZ-Bill-Pay-Agent-Network-SECURITY-1440x465.png";
import ContentWrapper from "components/public-dashboard/components/contentWrapper";
import PageBanner from "components/public-dashboard/components/page_banner";
import PartnersContent from "./content";
import PartnersHeader from "./header";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
 
export default function Partners() {
    useDocumentTitle("Secure");

  return (
    <Fragment>
      <PageBanner img={secureBanner} />
      <ContentWrapper>
        <PartnersHeader />
        <PartnersContent />
      </ContentWrapper>
    </Fragment>
  );
}
